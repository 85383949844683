import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0072ce", // blue
        secondary: "#003da6", // dark blue
        accent: "#ff671f", // orange
        info: "#aa0061", // purple
        success: "#509e2f", // green
        error: "#f9423a", // red
        warning: "#ffc72c", // yellow
        blue: "#0072ce",
        orange: "#ff671f",
        yellow: "#ffc72c",
        red: "#f9423a",
        purple: "#aa0061",
        darkblue: "#003da6",
        lightblue: "#b9d9eb",
        green: "#509e2f",
        primarytext: "#000000",
      },
    },
  },
});
