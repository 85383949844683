// von Thom konfigurierte URLs, die erlaubt sind:
// (wenn jemand weitere braucht, einfach Thom sagen)
// http://localhost
// http://localhost:8080
// https://cis-cicd.lab4you.at/
// https://cis-cicd.lab4you.at/marko-auth/

// doc:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser#usage

import { PublicClientApplication } from "@azure/msal-browser";

let redirectUri;
const urlRegexp = new RegExp("(https://cis-cicd.lab4you.at/.*/).*");
// for local development
if (window.location.origin === "http://localhost") {
  redirectUri = "http://localhost/";
  // for cicd review-apps
} else if (urlRegexp.test(window.location)) {
  redirectUri = urlRegexp.exec(window.location)[1];
  // for prod locations
} else if (
  window.location.origin === "https://cis-20.azurewebsites.net" ||
  window.location.origin === "https://cis.cisv.at"
) {
  redirectUri = window.location.origin + "/";
  // deny all other locations
} else {
  throw new Error("Deployment auf nicht-unterstützter URL");
}

const msalConfig = {
  auth: {
    clientId: "71a7171b-7df8-4611-a011-57f5a3ec3abf",
    authority:
      "https://login.microsoftonline.com/684369b0-3b43-4223-aeba-729d8b217496",
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance };
