<template>
  <v-card v-if="data.length !== 0">
    <v-tabs :color="color">
      <v-tabs-slider></v-tabs-slider>
      <!-- loop through the data given by parent component (e.g. Personen zu einer Adresse) -->
      <!-- Tab (e.g. Person1 von Adresse) -->
      <v-tab v-for="(item, index) in data" :key="index">{{
        item[connectedGroups.tabTitleSource]
          ? item[connectedGroups.tabTitleSource]
          : "kein " + connectedGroups.tabTitleSource
      }}</v-tab>
      <!-- item (e.g. Person1 von Adresse) -->
      <v-tab-item v-for="(item, index) in data" :key="index">
        <v-card elevation="0">
          <v-card-text>
            <div class="grid-wrapper" :style="cssVars">
              <div
                v-for="(subkey, subindex) in connectedGroups.values"
                :key="subindex"
              >
                <span>{{ subkey.text + ": " }}</span>
                <span
                  :class="
                    subkey.highlight ? 'font-weight-black' : 'font-weight-bold'
                  "
                >
                  {{ item[subkey.value] }}
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
  <!-- default text if there is no associated data -->
  <span v-else>{{ "keine " + type }}</span>
</template>

<script>
export default {
  name: "DetailViewAddon",
  props: ["data", "connectedGroups", "type", "color"],
  computed: {
    // specifies css variables used in the styling below
    cssVars() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return {
          "--n-cols": 1,
        };
      } else {
        return {
          "--n-cols": 2,
        };
      }
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(var(--n-cols), 1fr);
  grid-auto-flow: row;
  gap: 0px 16px;
}
</style>
