<template>
  <v-card min-width="256">
    <v-card-title>CISV Links</v-card-title>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item
        v-for="link in links"
        :key="link.name"
        :href="link.url"
        target="_blank"
      >
        <v-list-item-title>{{ link.name }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "ExternalLinksView",
  data() {
    return {
      links: [
        { url: "https://www.cisv.at", name: "Über CISV" },
        {
          url: "https://www.cisv.at/programme/aktuelle-programme/",
          name: "Aktuelle Programme",
        },
        { url: "https://mycisv.cisv.org/", name: "MyCISV Portal" },
        // disabled in first release
        //{ url: "#", name: "Benutzer-Hilfe" },
      ],
    };
  },
};
</script>
