<template>
  <div>
    <!-- Loading Failed Alert -->
    <v-alert
      v-if="failedLoading"
      type="error"
      prominent
      elevation="2"
      max-width="768"
      class="mx-auto mt-10"
    >
      <v-row align="center">
        <v-col class="grow">Fehler beim Laden. Bitte erneut versuchen.</v-col>
        <v-col class="shrink">
          <v-btn @click="reset()">
            <v-icon left>mdi-reload</v-icon>
            <span>neu laden</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <!-- Data Table -->
    <div v-else fill-height>
      <v-data-table
        disable-sort
        :headers="headers"
        :items="data"
        :item-key="id"
        :options.sync="options"
        :server-items-length="totalResults"
        :loading="isLoading"
        show-select
        v-model="selected"
        @click:row="handleRowClick($event)"
        :hide-default-footer="false"
        loading-text="Ladet... Bitte warten"
        no-data-text="Keine Daten vorhanden"
        no-results-text="Keine Suchergebnisse gefunden"
        :header-props="{
          sortByText: 'Sortieren nach',
        }"
        :footer-props="{
          pageText: '{0}-{1} von {2}',
          itemsPerPageText: 'Zeilen pro Seite:',
          itemsPerPageOptions: [5, 10, 25, 50, 100],
          showFirstLastPage: true,
          showCurrentPage: true,
        }"
        fixed-header
        :height="tableHeight"
      >
        <!-- Data Table Title Bar -->
        <template v-slot:top>
          <v-toolbar flat class="my-2 my-sm-0">
            <div
              class="d-flex justify-space-between flex-wrap"
              style="width: 100%"
            >
              <v-toolbar-title class="mr-4 text-h5"
                ><v-icon left :color="resourceColor" class="mb-1">{{
                  resourceIcon
                }}</v-icon>
                {{ displayName }}</v-toolbar-title
              >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                name="table-search"
                label="Durchsuche Tabelle"
                single-line
                hide-details
                clearable
                style="max-width: 512px"
                @input="handleSearch($event)"
              >
              </v-text-field>
            </div>
          </v-toolbar>
        </template>

        <!-- disabled in first release -->
        <!-- Custom Filter 
        <template v-slot:[`body.prepend`]>
          <tr class="d-none d-sm-table-row">
            <td></td>
            <td v-for="header in headers" :key="header.value">
              <v-text-field
                v-if="header.filterable != false"
                clearable
                dense
                placeholder="Filter eingeben..."
                prepend-inner-icon="mdi-filter"
                class="mt-4"
              ></v-text-field>
            </td>
          </tr>
        </template> -->

        <!-- disabled in first release -->
        <!-- CRUD Actions -->
        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-btn disabled icon @click.stop="editItem(item)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn disabled icon @click.stop="deleteItem(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-row>
        </template> -->

        <!-- No Data Found -->
        <template v-slot:no-data>
          <v-alert
            type="error"
            prominent
            elevation="2"
            max-width="768"
            class="mx-auto mt-10"
          >
            <v-row align="center">
              <v-col class="grow">
                Fehler beim Laden. Bitte erneut versuchen.
              </v-col>
              <v-col class="shrink">
                <v-btn @click="reset()">
                  <v-icon left>mdi-reload</v-icon>
                  <span>neu laden</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </template>

        <!-- Footer Buttons -->
        <template v-slot:[`footer.prepend`]>
          <div
            class="
              d-flex
              justify-space-between
              flex-grow-1 flex-sm-grow-0
              mt-2 mt-sm-0
              ml-sm-2
            "
          >
            <v-btn color="accent" class="mr-1" @click="downloadDataAsCsv()">
              <v-icon left>mdi-file-export</v-icon>
              Exportieren
            </v-btn>
            <!-- disabled in first release -->
            <!-- <v-btn disabled class="ml-1">
              <v-icon left>{{ newEntryIcon }}</v-icon>
              Neuer Eintrag
            </v-btn> -->
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- Edit Dialog -->
    <v-dialog v-model="editDialog" max-width="512">
      <v-card>
        <v-card-title>Edit Dialog</v-card-title>
        <v-card-text>Edit forms here</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="editDialog = false">Abbrechen</v-btn>
          <v-btn color="green" text @click="editDialog = false"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="512">
      <v-card>
        <v-card-title>Wollen Sie den Eintrag wirklich löschen?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="deleteDialog = false"
            >Ja, löschen</v-btn
          >
          <v-btn color="green" text @click="deleteDialog = false"
            >Nein, abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { fetchData } from "../api";
import {
  getResourceId,
  getResourceHeaders,
  getResourceTitle,
  getResourceColor,
  getResourceIcon,
  getNewEntryIcon,
} from "../api/resources";
import { provideDownloadCsv } from "../utils/provideDownload.js";
function getInitialState() {
  return {
    isLoading: false,
    failedLoading: false,
    headers: [],
    metadata: [],
    data: [],
    selected: [],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    totalResults: 0,
    search: "",
    debounce: null,
    id: "",
    displayName: "",
    editDialog: false,
    deleteDialog: false,
    resourceColor: "",
    resourceIcon: "",
    newEntryIcon: "",
    viewportHeight: document.documentElement.clientHeight,
  };
}
export default {
  name: "DataView",
  props: {
    // resource identifier, e.g. "adressen"
    resource: String,
  },
  data() {
    return getInitialState();
  },
  methods: {
    reset() {
      // replace the state with the initial state
      Object.assign(this.$data, getInitialState());
    },
    async loadData() {
      this.isLoading = true;
      let headers;
      try {
        headers = [
          ...getResourceHeaders(this.resource),
          // disabled in first release
          // {
          //   text: "Aktionen",
          //   value: "actions",
          //   sortable: false,
          //   filterable: false,
          // },
        ];
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let metadata;
      let data;
      try {
        let url = this.resource.toLowerCase();
        url += "?offset=" + (this.options.page - 1) * this.options.itemsPerPage;
        url += "&limit=" + this.options.itemsPerPage;
        url += "&suchfilter=" + this.search;
        ({ metadata, data } = await fetchData(url));
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let id;
      try {
        id = await getResourceId(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }

      this.headers = headers;
      this.metadata = metadata;
      this.data = data;
      this.totalResults = parseInt(metadata["x-total-results"]);
      this.id = id;
      this.displayName = getResourceTitle(this.resource);
      this.resourceColor = getResourceColor(this.resource);
      this.resourceIcon = getResourceIcon(this.resource);
      this.newEntryIcon = getNewEntryIcon(this.resource);
      this.isLoading = false;
      this.failedLoading = false;
    },
    handleRowClick(event) {
      // redirect to detail view
      let resourceID = event[this.id];
      this.$router.push({
        name: "DetailView",
        params: { resource: this.resource, id: resourceID },
      });
    },
    handleSearch(event) {
      // debounces search with a 0.5 sec delay
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        // clear buttons sets event to null
        if (event == null) {
          this.search = "";
        } else {
          this.search = event;
        }
        this.options.page = 1; // can make additional api call
        this.loadData();
      }, 500);
    },
    editItem(item) {
      console.log(`TODO: edit item ${item}`);
      this.editDialog = true;
    },
    deleteItem(item) {
      console.log(`TODO: delete item ${item}`);
      this.deleteDialog = true;
    },
    async downloadDataAsCsv() {
      let now = new Date();
      const YYYY = now.getFullYear();
      const MM = (now.getMonth() + 1).toString().padStart(2, "0");
      const DD = now.getDate().toString().padStart(2, "0");
      const hh = now.getHours().toString().padStart(2, "0");
      const mm = now.getMinutes().toString().padStart(2, "0");
      const ss = now.getSeconds().toString().padStart(2, "0");
      const resourceTitle = getResourceTitle(this.resource);
      const filename = `CISV-Export_${resourceTitle}_${YYYY}-${MM}-${DD}_${hh}-${mm}-${ss}.csv`;

      if (this.selected.length === 0) {
        // separate API call with export flag
        let url = this.resource.toLowerCase();
        url += "?suchfilter=" + this.search;
        url += "&export=true";
        let data;
        try {
          ({ data } = await fetchData(url));
        } catch (err) {
          console.log(err);
          return;
        }
        provideDownloadCsv(data, filename);
      } else {
        provideDownloadCsv(this.selected, filename);
      }
    },
    updateViewportHeight() {
      this.viewportHeight = document.documentElement.clientHeight;
    },
  },
  watch: {
    resource() {
      this.reset(); // seems to also invoke loadData
      // this.loadData();
    },
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  mounted() {
    // this.loadData(); // seems to not be needed
    window.addEventListener("resize", this.updateViewportHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.updateViewportHeight);
  },
  computed: {
    tableHeight() {
      // returns height in pixels for the data table body to fill
      // subtracts appbar, table header and footer from viewport height
      // TODO: get these values dynamically
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // mobile screens
          return this.viewportHeight - 56 - 72 - 139;
        case "sm": // tablet screens
          return this.viewportHeight - 56 - 56 - 96;
      }
      // other cases "md", "lg" and "xl":
      return this.viewportHeight - 64 - 64 - 60;
    },
  },
};
</script>
