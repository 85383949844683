<template>
  <div>
    <div>
      <v-text-field label="Suche" v-model="queryParams.suchfilter" />
    </div>

    <div v-if="queryStatus === 'empty'">
      <v-alert border="top" colored-border type="info" elevation="2">
        Bitte einen Suchbegriff eingeben!
      </v-alert>
    </div>
    <div v-if="queryStatus === 'loading'">lade...</div>
    <div v-if="queryStatus === 'error'">Fehler beim Laden</div>
    <div v-else-if="queryStatus === 'success'">
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="-1"
        :hide-default-footer="true"
        class="elevation-2"
      >
        <template v-slot:[`item.Entity`]="{ item }">
          <v-chip
            :to="getChipLink(item.Entity)"
            :color="getChipColor(item.Entity)"
          >
            {{ item.Entity }}
          </v-chip>
        </template>

        <template v-slot:[`item.ID`]="{ item }">
          <v-btn
            :to="getItemLink(item.Entity, item.ID)"
            icon
            small
            class="mr-2"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div>
        <v-btn @click="offset = 0" :disabled="offset === 0">Anfang</v-btn>
        <v-btn @click="offset = offset - limit" :disabled="offset === 0">
          zurück
        </v-btn>
        <v-btn @click="offset = offset + limit">weiter</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * GlobalSearch standalone component
 *
 * Data is displayed in a table.
 *
 * props (passed from the parent component):
 * - resource identifier (e.g. "adressen")
 *
 * state (internal to the component):
 * - query details (offset, limit, parameters)
 * - query status (loading / error / success)
 * - query result (headers, data)
 */

// DEPENDENCIES
import { fetchData } from "../api";
import {
  getResourceNameForEntity,
  getResourceColor,
  getResourceHeaders,
  getResourceLink,
} from "../api/resources";

// EXPORTS
export default {
  name: "GlobalSearch",
  data() {
    return {
      resource: "suche",
      offset: 0,
      limit: 50,
      queryParams: {
        suchfilter: "",
      },
      queryStatus: "loading",
      headers: [],
      data: [],
    };
  },
  methods: {
    async loadData() {
      // do nothing if queryParams.suchfilter is empty/undefined
      if (!this.queryParams.suchfilter) {
        this.queryStatus = "empty";
        return;
      }
      this.queryStatus = "loading";
      // get headers first
      let headers;
      try {
        headers = await getResourceHeaders(this.resource);
      } catch (err) {
        console.log(err);
        this.queryStatus = "error";
        return;
      }
      // get data
      let data;
      try {
        let url = this.resource;
        url += "?offset=" + this.offset;
        url += "&limit=" + this.limit;
        url += "&suchfilter=" + this.queryParams.suchfilter;
        data = await fetchData(url);
      } catch (err) {
        console.log(err);
        this.queryStatus = "error";
        return;
      }
      // update states
      this.headers = headers;
      this.data = data;
      this.queryStatus = "success";
    },
    getChipColor(entityName) {
      let resourceName = getResourceNameForEntity(entityName);
      return getResourceColor(resourceName);
    },
    getChipLink(entityName) {
      let resourceName = getResourceNameForEntity(entityName);
      return getResourceLink(resourceName);
    },
    getItemLink(entityName, itemId) {
      let resourceName = getResourceNameForEntity(entityName);
      let baseUrl = getResourceLink(resourceName);
      return `${baseUrl}/${itemId}`;
    },
  },
  watch: {
    offset() {
      this.loadData();
    },
    limit() {
      this.loadData();
    },
    queryParams: {
      deep: true,
      handler() {
        this.offset = 0;
        this.loadData();
      },
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
