<template>
  <v-app-bar app color="primary" dark clipped-left>
    <v-row no-gutters align="center" class="flex-nowrap">
      <img
        width="49"
        height="42"
        src="../assets/cisv_logo.png"
        alt="CISV Logo"
      />
      <span class="mx-2 white--text font-weight-medium" style="font-size: 2rem">
        CIS:web
      </span>
    </v-row>

    <v-spacer />

    <v-btn color="accent" @click="$emit('logIn')">
      <v-icon left>mdi-account</v-icon>
      Anmelden
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBarLoggedOut",
};
</script>
