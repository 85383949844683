<template>
  <v-navigation-drawer
    app
    clipped
    :value="value"
    @input="$emit('input', $event)"
  >
    <div class="d-flex flex-column justify-space-between" style="height: 98%">
      <v-list nav>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon v-if="$route.name === 'Home'">mdi-home</v-icon>
            <v-icon v-else>mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Startseite</v-list-item-title>
        </v-list-item>

        <v-divider class="mb-2" />

        <v-list-item
          v-for="view in views"
          :key="view.name"
          :to="view.url"
          :color="view.color"
        >
          <v-list-item-icon>
            <v-icon :color="view.color">{{ view.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="color: var(--v-primarytext-base)">{{
            view.name
          }}</v-list-item-title>
        </v-list-item>

        <!-- disabled in first release -->
        <!-- <v-divider class="mb-2" />
        <v-list-item to="#">
          <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item> -->
      </v-list>

      <div class="align-self-center text-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text-caption discreet">
              <a
                href="mailto:contact-project+cisv-vienna-cis20-cis-web-24402405-issue-@incoming.gitlab.com?subject=[BUG-REPORT]%20PROBLEM-BESCHREIBUNG"
                >version: {{ version }}</a
              >
            </span>
          </template>
          <span>Fehlerbericht senden</span>
        </v-tooltip>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppNavigationDrawer",
  data: () => ({
    // todo: get this from resurces.js
    views: [
      {
        url: "/adressen",
        name: "Adressen",
        icon: "mdi-map-marker-multiple",
        color: "yellow",
      },
      {
        url: "/personen",
        name: "Personen",
        icon: "mdi-account-box-multiple",
        color: "red",
      },
      {
        url: "/anmeldungen",
        name: "Anmeldungen",
        icon: "mdi-clipboard-check-multiple-outline",
        color: "purple",
      },
      {
        url: "/einladungen",
        name: "Einladungen",
        icon: "mdi-email-multiple",
        color: "darkblue",
      },
      {
        url: "/veranstaltungen",
        name: "Veranstaltungen",
        icon: "mdi-calendar-multiple",
        color: "green",
      },
    ],
  }),
  props: ["value", "version"],
};
</script>
<style scoped>
.discreet {
  color: #ddd;
  font-size: small;
}
.discreet a {
  color: #bbb;
  text-decoration: none;
}
</style>
