var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","clipped-left":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({attrs:{"aria-label":"Menü öffnen"},on:{"click":function($event){return _vm.$emit('toggleDrawerOpen')}}},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c('span',[_vm._v("Menü öffnen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/","aria-label":"Zur Startseite"}},[_c('v-row',_vm._g(_vm._b({staticClass:"d-none d-sm-flex flex-nowrap",attrs:{"no-gutters":"","align":"center"}},'v-row',attrs,false),on),[_c('img',{staticClass:"ml-4",attrs:{"width":"49","height":"42","src":require("../assets/cisv_logo.png"),"alt":"CISV Logo"}}),_c('span',{staticClass:"d-none d-md-inline mx-2 white--text font-weight-medium",staticStyle:{"font-size":"2rem"}},[_vm._v("CIS:web")])])],1)]}}])},[_c('span',[_vm._v("Zur Startseite")])]),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mx-2 mt-1 mt-md-2 align-self-start",attrs:{"messages":_vm.globalSearch.messages,"items":_vm.globalSearchItems,"loading":_vm.globalSearch.loading,"search-input":_vm.globalSearchString,"label":"Globale Suche","placeholder":"Suchbegriff eingeben...","dense":"","outlined":"","hide-no-data":"","clearable":"","prepend-inner-icon":"mdi-magnify"},on:{"update:searchInput":function($event){_vm.globalSearchString=$event},"update:search-input":function($event){_vm.globalSearchString=$event}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('v-alert',{staticClass:"mt-1 mt-md-2 text-caption",attrs:{"dense":"","type":_vm.globalSearch.error ? 'error' : 'info',"elevation":"1"}},[_vm._v(_vm._s(message))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]}}]),model:{value:(_vm.globalSearchItem),callback:function ($$v) {_vm.globalSearchItem=$$v},expression:"globalSearchItem"}}),_c('v-spacer'),_c('v-menu',{attrs:{"rounded":"","offset-y":"","bottom":"","nudge-bottom":"6","min-width":"128px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-n2",attrs:{"icon":"","x-large":"","aria-label":"Profil öffnen"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-avatar',{attrs:{"color":"accent","size":"40"}},[_c('span',{staticClass:"white--text text-subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.accountInitials)+" ")])])],1)]}}],null,true)},[_c('span',[_vm._v("Profil öffnen")])])]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center px-4"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{staticClass:"mb-2",attrs:{"color":"accent"}},[_c('span',{staticClass:"white--text text-h6 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.accountInitials)+" ")])]),_c('p',{staticClass:"font-weight-bold my-1"},[_vm._v(_vm._s(_vm.account.name))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(_vm._s(_vm.account.idTokenClaims.email))]),_c('v-divider',{staticClass:"my-3"}),_c('v-list',{staticClass:"text-left"},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('logOut')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-title',[_vm._v("Abmelden")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }