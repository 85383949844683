<template>
  <v-container class="mt-8">
    <v-alert type="error" prominent max-width="768px" class="mx-auto">
      <v-row align="center">
        <v-col class="grow">
          <span class="text-subtitle-1">
            Fehler 404: Die Seite <kbd>{{ catchAll }}</kbd> wurde nicht
            gefunden.
          </span>
        </v-col>
        <v-col class="shrink">
          <v-btn to="/">Zurück zur Startseite</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundView",
  props: {
    catchAll: String,
  },
};
</script>
