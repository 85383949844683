import { unparse } from "papaparse";

/**
 * make the browser download the given data in CSV format
 */
function provideDownloadCsv(data, filename) {
  const serializedDataString =
    "\ufeff" + unparse(data, { delimiter: ";" });
  provideDownload(serializedDataString, "text/csv", filename);
}

/**
 * make the browser download the given data
 *
 * filetype can be a MIME type, e.g. "text/csv"
 */
function provideDownload(data, filetype, filename) {
  // source:
  // https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
  const blob = new Blob([data], { type: filetype });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

export { provideDownloadCsv };
