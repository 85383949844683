import { msalInstance } from "../authConfig";

const API_SERVICE_URL = process.env.VUE_APP_API_BASE_PATH + "/v1/";

/**
 * fetch data from the API
 * will automatically add an authorization header
 */
async function fetchData(url) {
  let fullUrl = API_SERVICE_URL + url;
  console.log(`[api] fetching data from ${fullUrl}`);
  const authResult = await msalInstance.acquireTokenSilent({
    scopes: ["openid"],
  });
  const res = await fetch(fullUrl, {
    headers: { Authorization: `Bearer ${authResult.idToken}` },
  });
  if (!res.ok) {
    throw new Error(res);
  }
  const data = await res.json();
  const headers = await res.headers;
  const metadata = Object.fromEntries(headers.entries());
  return { data: data, metadata: metadata };
}

export { fetchData };
