<template>
  <div class="mx-2">
    <v-container style="max-width: 1185px">
      <div class="mb-2">
        <v-icon left :color="resourceColor" class="mb-2">
          {{ resourceEntryIcon }}
        </v-icon>
        <span class="text-h5">{{ singularTitle }}</span>
      </div>
      <v-row class="flex-wrap">
        <v-col
          v-for="group in groups"
          :key="group.text"
          style="min-width: 256px"
        >
          <v-card height="100%">
            <v-card-title class="text-subtitle-1 font-weight-bold">
              {{ group.text }}
            </v-card-title>
            <v-card-text>
              <div v-for="(object, index) in group.values" :key="index">
                <span>{{ object.text + ": " }}</span>
                <span
                  :class="
                    object.highlight
                      ? `font-weight-black ${resourceColor}--text`
                      : 'font-weight-bold'
                  "
                >
                  {{ data[object.value] }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-6 mb-2">
        <v-icon left :color="connectedColor" class="mb-1">
          {{ connectedIcon }}
        </v-icon>
        <span class="text-h6 font-weight-regular">{{ connectedTitle }}</span>
      </div>
      <detail-view-addon
        v-if="connectedTitle != ''"
        :type="connectedTitle"
        :data="data[connectedTitle]"
        :connectedGroups="connectedGroups"
        :color="connectedColor"
      />
    </v-container>

    <!-- disabled in first release -->
    <!-- <v-btn
      @click="safeMode = !safeMode"
      elevation="2"
      fab
      fixed
      bottom
      right
      class="mb-12 mr-4"
      dark
    >
      <v-icon v-if="safeMode">mdi-pencil</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import DetailViewAddon from "./DetailViewAddon.vue";
import { fetchData } from "../api";
import {
  getResourceGroups,
  getResourceConnectedGroups,
  getResourceTitle,
  getResourceSingularTitle,
  getResourceConnectedTitle,
  getResourceColor,
  getEntryIcon,
  getResourceIcon,
} from "../api/resources";
export default {
  components: { DetailViewAddon },
  name: "DetailView",
  props: {
    resource: String,
    id: [String, Number],
  },
  data() {
    return {
      isLoading: false,
      failedLoading: false,
      safeMode: true,
      data: [],
      groups: [],
      connectedGroups: [],
      title: "",
      singularTitle: "",
      connectedTitle: "",
      resourceColor: "",
      resourceEntryIcon: "",
      connectedColor: "",
      connectedIcon: "",
      colored: "",
    };
  },
  methods: {
    async loadData() {
      let url = `${this.resource.toLowerCase()}/${parseInt(this.id)}`;
      this.isLoading = true;
      let groups;
      try {
        groups = await getResourceGroups(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let connectedGroups;
      try {
        connectedGroups = await getResourceConnectedGroups(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let data;
      try {
        ({ data } = await fetchData(url));
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let title;
      try {
        title = await getResourceTitle(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let singularTitle;
      try {
        singularTitle = await getResourceSingularTitle(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let connectedTitle;
      try {
        connectedTitle = await getResourceConnectedTitle(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let resourceColor;
      try {
        resourceColor = await getResourceColor(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      let resourceEntryIcon;
      try {
        resourceEntryIcon = await getEntryIcon(this.resource);
      } catch (err) {
        console.log(err);
        this.failedLoading = true;
        this.isLoading = false;
        return;
      }
      if (connectedTitle) {
        let connectedColor;
        try {
          connectedColor = await getResourceColor(connectedTitle.toLowerCase());
        } catch (err) {
          console.log(err);
          this.failedLoading = true;
          this.isLoading = false;
          return;
        }
        let connectedIcon;
        try {
          connectedIcon = await getResourceIcon(connectedTitle.toLowerCase());
        } catch (err) {
          console.log(err);
          this.failedLoading = true;
          this.isLoading = false;
          return;
        }
        this.connectedColor = connectedColor;
        this.connectedIcon = connectedIcon;
      }

      this.groups = groups;
      this.connectedGroups = connectedGroups;
      this.data = data;
      this.title = title;
      this.singularTitle = singularTitle;
      this.connectedTitle = connectedTitle;
      this.resourceColor = resourceColor;
      this.resourceEntryIcon = resourceEntryIcon;
      this.colored = resourceColor + "--text";
      this.isLoading = false;
      this.failedLoading = false;
    },
  },
  watch: {
    $route: "loadData",
  },
  mounted() {
    this.loadData();
  },
};
</script>
