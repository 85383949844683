<template>
  <v-app v-if="account">
    <app-navigation-drawer
      @input="drawerOpen = $event"
      :value="drawerOpen"
      :version="version"
    />
    <app-bar
      :account="account"
      @logOut="logOut()"
      @toggleDrawerOpen="drawerOpen = !drawerOpen"
    />
    <v-main>
      <v-container fluid class="ma-0 pa-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <app-bar-logged-out @logIn="logIn()" />
    <v-main>
      <logged-out-view @logIn="logIn()" />
    </v-main>
  </v-app>
</template>

<script>
import "./vuetify-overrides.css";
import { msalInstance } from "./authConfig";
import AppBar from "./components/AppBar.vue";
import AppNavigationDrawer from "./components/AppNavigationDrawer.vue";
import AppBarLoggedOut from "./components/AppBarLoggedOut.vue";
import LoggedOutView from "./views/LoggedOutView.vue";

export default {
  name: "App",
  components: { AppNavigationDrawer, AppBar, AppBarLoggedOut, LoggedOutView },
  data: () => ({
    account: null,
    // will be initialized according to screen width
    drawerOpen: null,
    version: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      this.account = activeAccount;
    }
  },
  methods: {
    async logIn() {
      const loginResponse = await msalInstance.loginPopup({
        prompt: "select_account",
        scopes: ["openid"],
      });
      this.account = loginResponse.account;
      msalInstance.setActiveAccount(loginResponse.account);
    },
    async logOut() {
      await msalInstance.logoutPopup({ account: this.account });
      this.account = null;
    },
  },
};
</script>
