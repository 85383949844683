import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./views/Home.vue";
import GlobalSearch from "./views/GlobalSearch.vue";
import DataView from "./views/DataView.vue";
import DetailView from "./views/DetailView.vue";
import NotFoundView from "./views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
  {
    // matches "/" and "/index.html";
    path: "/(index.html)?",
    name: "Home",
    component: Home,
    props: { atHome: true },
  },
  {
    path: "/suche",
    name: "Suche",
    component: GlobalSearch,
  },
  {
    // matches "/adressen", etc. (case insensitive); name is given to view as prop
    path: "/:resource(adressen|anmeldungen|einladungen|personen|veranstaltungen)",
    name: "DataView",
    component: DataView,
    props: true,
  },
  {
    // matches "/adresse/123", etc. (case insensitive, id can only be numbers); name and id is given to view as props
    path: "/:resource(adressen|anmeldungen|einladungen|personen|veranstaltungen)/:id(\\d+)",
    name: "DetailView",
    component: DetailView,
    props: true,
  },
  {
    // matches everything else
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundView,
    props: true,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.VUE_APP_VUE_BASE_PATH,
  routes,
});

export default router;
