/**
 * define and manage resource-specific properties and functions
 */

// resource name mapping between:
// - DB entity type (e.g. "Adresse")
// - API resource (e.g. "adressen")
const RESOURCE_NAMES_DB_TO_API = {
  Adresse: "adressen",
  Anmeldung: "anmeldungen",
  Einladung: "einladungen",
  Person: "personen",
  Veranstaltung: "veranstaltungen",
};

// resource properties
const RESOURCES = {
  adressen: {
    headers: [
      { text: "Anrede", value: "Anrede" },
      { text: "Nachname", value: "Bezeichnung" },
      { text: "PLZ", value: "Plz" },
      { text: "Ort", value: "Ort" },
      { text: "Land", value: "ISO3" },
      { text: "Chapter", value: "Chapter" },
      { text: "Status", value: "Status" },
      { text: "Anschreiben", value: "Anschreiben" },
    ],
    singularTitle: "Adresse",
    connectedTitle: "Personen",
    groups: [
      {
        text: "Grunddaten",
        values: [
          { text: "Anrede", value: "Anrede" },
          {
            text: "Familienname",
            value: "Bezeichnung",
            highlight: true,
          },
          { text: "Adresse", value: "Adresse" },
          { text: "Postleitzahl", value: "Plz" },
          { text: "Ort", value: "Ort" },
          { text: "Chapter", value: "Chapter" },
        ],
      },
      {
        text: "Unterstützung",
        values: [
          { text: "Gastfamilie", value: "Gastfamilie" },
          { text: "Sponsoring", value: "Sponsoring" },
          { text: "Sachspenden", value: "Sachspenden" },
          { text: "Transport", value: "Transport" },
          { text: "Kitchenstaff", value: "Kitchenstaff" },
          {
            text: "sonstige Unterstützungen",
            value: "Unterst_sonstige",
          },
        ],
      },
    ],
    connectedGroups: {
      // define attributes to show
      values: [
        { text: "Vorname", value: "Vorname" },
        { text: "Familienname", value: "Zuname" },
        { text: "Pronomen", value: "Pronomen" },
        { text: "Chapter", value: "Kuerzel" },
        { text: "Geburtsdatum", value: "Geburtsdatum" },
        { text: "Familienfunktion", value: "FamFunktion" },
        { text: "Mitgliedstatus", value: "Mitgliedstatus" },
        { text: "Eintrittsdatum", value: "Eintrittsdatum" },
        { text: "Austrittsdatum", value: "Austrittsdatum" },
        { text: "Beitragsklasse", value: "Beitragsklasse" },
        { text: "E-mail", value: "Email" },
        { text: "Kontakt per Mail", value: "Kontakt_Mail" },
        { text: "Tel. mobil", value: "Tel_mobil" },
        {
          text: "Kontakt per Handy",
          value: "Kontakt_Handy",
        },
        { text: "Kommentare", value: "Kommentar" },
      ],
      // define itemheader
      tabTitleSource: "Vorname",
    },
    id: "ID_Adresse",
    title: "Adressen",
    color: "yellow",
    icon: "mdi-map-marker-multiple",
    entryIcon: "mdi-map-marker",
    newEntryIcon: "mdi-map-marker-plus",
  },

  anmeldungen: {
    headers: [
      { text: "Person", value: "Person_lang" },
      { text: "Programm-Code", value: "ProgrammCode" },
      { text: "Anmeldungsstatus", value: "AnmStatus" },
      { text: "Funktion", value: "Funktion" },
      { text: "Geschlecht", value: "Geschlecht" },
      { text: "Chapter", value: "Chapter_Person" },
      { text: "Sending Chapter", value: "Chapter_Sending" },
    ],
    singularTitle: "Anmeldung",
    connectedTitle: "",
    groups: [
      {
        text: "Grunddaten",
        values: [
          {
            text: "Person",
            value: "Person_lang",
            highlight: true,
          },
          {
            text: "Programm",
            value: "ProgrammCode",
            highlight: true,
          },
          { text: "Delegation", value: "isDelegation" },
          { text: "Geschlecht", value: "Geschlecht" },
          { text: "Chapter", value: "Kuerzel" },
          { text: "Zielland", value: "NationalName" },
          { text: "Beginn", value: "Beginn_Dat" },
          { text: "Ende", value: "Ende_Dat" },
        ],
      },
      {
        text: "Anmeldestatus",
        values: [
          {
            text: "Veranstaltungsstatus",
            value: "ZeitStatus",
          },
          { text: "Anmeldungsstatus", value: "AnmStatus" },
          { text: "Mindestalter", value: "Alter_von" },
          { text: "Maximalalter", value: "Alter_bis" },
          { text: "Funktion", value: "Funktion" },
          { text: "myCISV", value: "myCISV" },
        ],
      },
    ],
    connectedGroups: [],
    color: "purple",
    icon: "mdi-clipboard-check-multiple-outline",
    id: "ID_Anmeldung",
    title: "Anmeldungen",
    entryIcon: "mdi-clipboard-check-outline",
    newEntryIcon: "mdi-clipboard-plus-outline",
  },

  einladungen: {
    headers: [
      { text: "Programm-Code", value: "ProgrammCode" },
      { text: "Typ", value: "EinlTyp" },
      { text: "Chapter", value: "Chapter" },
      { text: "Abgesagt", value: "isCancelled" },
      { text: "Alter von", value: "Alter_von" },
      { text: "Alter bis", value: "Alter_bis" },
      { text: "Beginn", value: "Beginn_Dat" },
      { text: "Ende", value: "Ende_Dat" },
    ],
    singularTitle: "Einladung",
    connectedTitle: "Anmeldungen",
    groups: [
      {
        text: "Grunddaten",
        values: [
          {
            text: "Programmcode",
            value: "ProgrammCode",
            highlight: true,
          },
          {
            text: "Einladungstyp",
            value: "EinlTyp",
            highlight: true,
          },
          { text: "Chapter", value: "Chapter" },
          { text: "Zielland", value: "NationalName" },
          { text: "Beginn", value: "Beginn_Dat" },
          { text: "Ende", value: "Ende_Dat" },
        ],
      },
      {
        text: "Weiteres",
        values: [
          { text: "Status", value: "ZeitStatus" },
          { text: "Part. Mindestalter", value: "Alter_von" },
          { text: "Part. Maximalalter", value: "Alter_bis" },
          { text: "Öffentlich", value: "isPublic" },
        ],
      },
    ],
    connectedGroups: {
      // define attributes to show
      values: [
        { text: "Person", value: "Person_lang" },
        { text: "Programm", value: "ProgrammCode" },
        { text: "Delegation", value: "isDelegation" },
        { text: "Geschlecht", value: "Geschlecht" },
        { text: "Chapter", value: "Chapter_Person" },
        {
          text: "Sending Chapter",
          value: "Chapter_Sending",
        },
        {
          text: "Veranstaltungsstatus",
          value: "ZeitStatus",
        },
        { text: "Anmeldungsstatus", value: "AnmStatus" },
        { text: "Funktion", value: "Funktion" },
        { text: "myCISV", value: "myCISV" },
      ],
      // define itemheader
      tabTitleSource: "Person_lang",
    },
    id: "ID_Einladung",
    title: "Einladungen",
    color: "darkblue",
    icon: "mdi-email-multiple",
    entryIcon: "mdi-email",
    newEntryIcon: "mdi-email-plus",
  },

  personen: {
    headers: [
      { text: "Vorname", value: "Vorname" },
      { text: "Nachname", value: "Zuname" },
      { text: "E-Mail", value: "Email" },
      { text: "Mitgliedstatus", value: "Mitgliedstatus" },
      { text: "Chapter", value: "Chapter" },
      { text: "Geburtsjahr", value: "GebJahr" },
      { text: "Geschlecht", value: "Geschlecht" },
    ],
    singularTitle: "Person",
    connectedTitle: "Anmeldungen",
    groups: [
      {
        text: "Grunddaten",
        values: [
          { text: "Vorname", value: "Vorname", highlight: true },
          {
            text: "Familienname",
            value: "Zuname",
            highlight: true,
          },
          { text: "Pronomen", value: "Pronomen" },
          { text: "Chapter", value: "Kuerzel" },
          { text: "Geburtsdatum", value: "Geburtsdatum" },
          { text: "Familienfunktion", value: "FamFunktion" },
          { text: "Mitgliedstatus", value: "Mitgliedstatus" },
          { text: "Eintrittsdatum", value: "Eintrittsdatum" },
          { text: "Austrittsdatum", value: "Austrittsdatum" },
          { text: "Beitragsklasse", value: "Beitragsklasse" },
        ],
      },
      {
        text: "Kontakt",
        values: [
          { text: "E-mail", value: "Email" },
          { text: "Kontakt per Mail", value: "Kontakt_Mail" },
          { text: "Tel. mobil", value: "Tel_mobil" },
          {
            text: "Kontakt per Handy",
            value: "Kontakt_Handy",
          },
          { text: "Kommentare", value: "Kommentar" },
        ],
      },
    ],
    connectedGroups: {
      // define attributes to show
      values: [
        { text: "Person", value: "Person_lang" },
        { text: "Programm", value: "ProgrammCode" },
        { text: "Delegation", value: "isDelegation" },
        { text: "Geschlecht", value: "Geschlecht" },
        { text: "Chapter", value: "Chapter_Person" },
        {
          text: "Sending Chapter",
          value: "Chapter_Sending",
        },
        { text: "Mindestalter", value: "Alter_von" },
        { text: "Maximalalter", value: "Alter_bis" },
        { text: "Zielland", value: "NationalName" },
        { text: "Beginn", value: "Beginn_Dat" },
        { text: "Ende", value: "Ende_Dat" },
        {
          text: "Veranstaltungsstatus",
          value: "ZeitStatus",
        },
        { text: "Anmeldungsstatus", value: "AnmStatus" },
        { text: "Funktion", value: "Funktion" },
        { text: "myCISV", value: "myCISV" },
      ],
      // define itemheader
      tabTitleSource: "ProgrammCode",
    },
    id: "ID_Person",
    title: "Personen",
    color: "red",
    icon: "mdi-account-box-multiple",
    entryIcon: "mdi-account-box",
    newEntryIcon: "mdi-account-plus",
  },

  veranstaltungen: {
    headers: [
      { text: "Programm-Code", value: "ProgrammCode" },
      { text: "Land", value: "NationalName" },
      { text: "Chapter", value: "ChapterName" },
      { text: "Programm", value: "Programm" },
      { text: "Jahr", value: "Jahr" },
      { text: "Alter von", value: "Alter_von" },
      { text: "Alter bis", value: "Alter_bis" },
      { text: "Beginn", value: "Beginn_Dat" },
      { text: "Ende", value: "Ende_Dat" },
      { text: "Abgesagt", value: "isCancelled" },
    ],
    singularTitle: "Veranstaltung",
    connectedTitle: "Einladungen",
    groups: [
      {
        text: "Grunddaten",
        values: [
          {
            text: "Programmcode",
            value: "ProgrammCode",
            highlight: true,
          },
          { text: "Programmtyp", value: "Programm" },
          { text: "Zielland", value: "NationalName" },
          { text: "Zielchapter", value: "ChapterName" },
          { text: "Beginn", value: "Beginn_Dat" },
          { text: "Ende", value: "Ende_Dat" },
        ],
      },
      {
        text: "Weiteres",
        values: [
          { text: "Status", value: "ZeitStatus" },
          {
            text: "Part. Mindestalter",
            value: "Alter_von",
          },
          { text: "Part. Maximalalter", value: "Alter_bis" },
          { text: "Kommentare", value: "Kommentar" },
        ],
      },
    ],
    connectedGroups: {
      // define attributes to show
      values: [
        {
          text: "Programm",
          value: "Veranstaltung_lang",
        },
        { text: "Einladungstyp", value: "EinlTyp" },
        { text: "Sending Chapter", value: "Chapter" },
        { text: "Mindestalter", value: "Alter_von" },
        { text: "Maximalalter", value: "Alter_bis" },
        { text: "Zielland", value: "NationalName" },
        { text: "Status", value: "ZeitStatus" },
        { text: "Öffentlich", value: "isPublic" },
      ],
      // define itemheader
      tabTitleSource: "EinlTyp",
    },
    id: "ID_Veranstaltung",
    title: "Veranstaltungen",
    color: "green",
    icon: "mdi-calendar-multiple",
    entryIcon: "mdi-calendar",
    newEntryIcon: "mdi-calendar-plus",
  },

  suche: {
    headers: [
      { text: "Stammdaten", value: "Entity" },
      { text: "Ergebnis", value: "Ergebnis" },
      { text: "Aktionen", value: "ID" },
    ],
    id: "ID",
    title: "Globale Suche",
  },
};

/**
 * returns resource-specific headers for data table view
 */
function getResourceHeaders(resourceName) {
  return RESOURCES[resourceName].headers;
}

/**
 * returns resource-specific groups for data table view
 */
function getResourceGroups(resourceName) {
  return RESOURCES[resourceName].groups;
}

/**
 * returns resource-specific groups for associated data table view
 */
function getResourceConnectedGroups(resourceName) {
  return RESOURCES[resourceName].connectedGroups;
}

/**
 * returns resource-specific database ID
 */
function getResourceId(resourceName) {
  return RESOURCES[resourceName].id;
}

/**
 * returns link to the resource's endpoint
 */
function getResourceLink(resourceName) {
  return `/${resourceName}`;
}

/**
 * returns resource title (display name)
 */
function getResourceTitle(resourceName) {
  return RESOURCES[resourceName].title;
}

function getResourceSingularTitle(resourceName) {
  return RESOURCES[resourceName].singularTitle;
}

/**
 * returns resource connected title (display name)
 */
function getResourceConnectedTitle(resourceName) {
  return RESOURCES[resourceName].connectedTitle;
}

/**
 * returns resource color
 */
function getResourceColor(resourceName) {
  return RESOURCES[resourceName].color;
}

/**
 * returns resource icon
 */
function getResourceIcon(resourceName) {
  return RESOURCES[resourceName].icon;
}

/**
 * returns entry icon
 */
function getEntryIcon(resourceName) {
  return RESOURCES[resourceName].entryIcon;
}

/**
 * returns new entry icon
 */
function getNewEntryIcon(resourceName) {
  return RESOURCES[resourceName].newEntryIcon;
}

/**
 * given a DB entity type (e.g. "Adresse"),
 * returns the corresponding API resource name (e.g. "adressen")
 */
function getResourceNameForEntity(entityName) {
  return RESOURCE_NAMES_DB_TO_API[entityName];
}

/**
 * export functions
 */
export {
  getResourceHeaders,
  getResourceGroups,
  getResourceConnectedGroups,
  getResourceId,
  getResourceLink,
  getResourceTitle,
  getResourceSingularTitle,
  getResourceConnectedTitle,
  getResourceColor,
  getResourceIcon,
  getEntryIcon,
  getNewEntryIcon,
  getResourceNameForEntity,
};
