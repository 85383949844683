<template>
  <v-app-bar app color="primary" dark clipped-left>
    <!-- Left: Menu Button & Title -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          @click="$emit('toggleDrawerOpen')"
          aria-label="Menü öffnen"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span>Menü öffnen</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <router-link
          to="/"
          class="text-decoration-none"
          aria-label="Zur Startseite"
        >
          <v-row
            no-gutters
            align="center"
            class="d-none d-sm-flex flex-nowrap"
            v-bind="attrs"
            v-on="on"
          >
            <img
              width="49"
              height="42"
              class="ml-4"
              src="../assets/cisv_logo.png"
              alt="CISV Logo"
            />
            <span
              class="d-none d-md-inline mx-2 white--text font-weight-medium"
              style="font-size: 2rem"
              >CIS:web</span
            >
          </v-row>
        </router-link>
      </template>
      <span>Zur Startseite</span>
    </v-tooltip>

    <v-spacer />

    <!-- Center: Global Search -->
    <v-autocomplete
      v-model="globalSearchItem"
      :messages="globalSearch.messages"
      :items="globalSearchItems"
      :loading="globalSearch.loading"
      :search-input.sync="globalSearchString"
      label="Globale Suche"
      placeholder="Suchbegriff eingeben..."
      dense
      outlined
      hide-no-data
      clearable
      prepend-inner-icon="mdi-magnify"
      class="mx-2 mt-1 mt-md-2 align-self-start"
    >
      <template v-slot:message="{ message }">
        <v-alert
          dense
          :type="globalSearch.error ? 'error' : 'info'"
          elevation="1"
          class="mt-1 mt-md-2 text-caption"
          >{{ message }}</v-alert
        >
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-icon>
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-spacer />

    <!-- Right: Profile -->
    <v-menu rounded offset-y bottom nudge-bottom="6" min-width="128px">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              x-large
              class="mr-n2"
              aria-label="Profil öffnen"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-avatar color="accent" size="40">
                <span class="white--text text-subtitle-1 font-weight-medium">
                  {{ accountInitials }}
                </span>
              </v-avatar>
            </v-btn>
          </template>
          <span>Profil öffnen</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-list-item-content class="justify-center px-4">
          <div class="mx-auto text-center">
            <v-avatar color="accent" class="mb-2">
              <span class="white--text text-h6 font-weight-medium">
                {{ accountInitials }}
              </span>
            </v-avatar>
            <p class="font-weight-bold my-1">{{ account.name }}</p>
            <p class="text-caption mt-1">{{ account.idTokenClaims.email }}</p>
            <v-divider class="my-3"></v-divider>
            <v-list class="text-left">
              <!-- disabled in first release -->
              <!-- <v-list-item to="#">
                <v-list-item-icon
                  ><v-icon>mdi-account-cog</v-icon></v-list-item-icon
                >
                <v-list-item-title>Profil Einstellungen</v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="$emit('logOut')">
                <v-list-item-icon
                  ><v-icon>mdi-logout-variant</v-icon></v-list-item-icon
                >
                <v-list-item-title>Abmelden</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { fetchData } from "../api";
import {
  getResourceNameForEntity,
  getResourceColor,
  getEntryIcon,
} from "../api/resources";

export default {
  name: "AppBar",
  data: () => ({
    globalSearchItem: null,
    globalSearchString: "",
    globalSearch: {
      active: false,
      error: false,
      items: [],
      limit: 50,
      loading: false,
      messages: null,
      offset: 0,
      resource: "suche",
    },
    debounce: null,
  }),
  props: ["account"],
  // NOTE: JSDoc annotations are required here for Vetur support
  // see https://vuejs.github.io/vetur/guide/FAQ.html#property-xxx-does-not-exist-on-type-combinedvueinstance
  computed: {
    /** @returns {array} */
    globalSearchItems() {
      return this.globalSearch.items;
    },
    /** @returns {boolean} */
    isGlobalSearchActive() {
      return this.globalSearchString && this.globalSearchString !== "";
    },
    /** @returns {string} */
    accountInitials() {
      let idTokenClaims = this.account.idTokenClaims;
      if (idTokenClaims.family_name && idTokenClaims.given_name) {
        return (
          idTokenClaims.given_name.slice(0, 1) +
          idTokenClaims.family_name.slice(0, 1)
        );
      } else {
        return idTokenClaims.name.slice(0, 1);
      }
    },
  },
  methods: {
    resetGlobalSearch() {
      this.globalSearch.error = false;
      this.globalSearch.items = [];
      this.globalSearch.loading = false;
      this.globalSearch.messages = null;
    },
    async loadData() {
      this.globalSearch.loading = true;
      let url = this.globalSearch.resource;
      url += "?offset=" + this.globalSearch.offset;
      url += "&limit=" + this.globalSearch.limit;
      url += "&suchfilter=" + this.globalSearchString;
      let data;
      try {
        ({ data } = await fetchData(url));
      } catch (err) {
        console.log(err);
        this.globalSearch.error = true;
        this.globalSearch.messages =
          "Fehler beim Laden. Bitte erneut versuchen.";
        return;
      } finally {
        this.globalSearch.loading = false;
      }
      // compute globalSearch.items
      let items = data.map((item) => {
        let resourceName = getResourceNameForEntity(item.Entity);
        let returnObject = {
          text: item.Ergebnis,
          value: {
            id: item.ID,
            resource: item.Entity,
          },
          disabled: false,
          color: getResourceColor(resourceName),
          icon: getEntryIcon(resourceName),
        };
        return returnObject;
      });
      console.log(`${items.length} items found`);
      // notify if no results
      if (items.length === 0) {
        this.globalSearch.messages = "Kein Suchtreffer!";
      } else {
        this.globalSearch.error = false;
      }
      this.globalSearch.items = items;
    },
  },
  watch: {
    globalSearchItem(val) {
      this.$router.push({
        name: "DetailView",
        params: {
          resource: getResourceNameForEntity(val.resource),
          id: val.id,
        },
      });
      this.resetGlobalSearch();
    },
    globalSearchString(val) {
      // cancel any previous query
      clearTimeout(this.debounce);
      // clear button sets value to null
      if (val === null || val.length === 0) {
        this.resetGlobalSearch();
        return;
      }
      this.debounce = setTimeout(() => {
        this.loadData();
      }, 500);
    },
  },
};
</script>
