<template>
  <v-card min-width="256">
    <v-card-title>Stammdaten</v-card-title>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item
        v-for="view in views"
        :key="view.name"
        :to="view.url"
        :color="view.color"
      >
        <v-list-item-icon>
          <v-icon :color="view.color">{{ view.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="color: var(--v-primarytext-base)">{{
          view.name
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "InternalLinksView",
  data() {
    return {
      // todo: get this from resurces.js
      views: [
        {
          url: "/adressen",
          name: "Adressen",
          icon: "mdi-map-marker-multiple",
          color: "yellow",
        },
        {
          url: "/personen",
          name: "Personen",
          icon: "mdi-account-box-multiple",
          color: "red",
        },
        {
          url: "/anmeldungen",
          name: "Anmeldungen",
          icon: "mdi-clipboard-check-multiple-outline",
          color: "purple",
        },
        {
          url: "/einladungen",
          name: "Einladungen",
          icon: "mdi-email-multiple",
          color: "darkblue",
        },
        {
          url: "/veranstaltungen",
          name: "Veranstaltungen",
          icon: "mdi-calendar-multiple",
          color: "green",
        },
      ],
    };
  },
};
</script>
