<template>
  <v-container class="pa-0" style="max-width: 100%">
    <!-- CISV Logo -->
    <v-img
      src="../assets/austria_combined_col.png"
      min-height="42"
      height="20vh"
      max-height="128"
      max-width="80vw"
      contain
      class="mx-auto mt-6 px-4"
      alt="CISV Logo"
    >
    </v-img>

    <!-- Image Slider -->
    <v-carousel
      cycle
      show-arrows-on-hover
      hide-delimiters
      height="clamp(128px, 20vh, 400px)"
      class="mt-4"
    >
      <v-carousel-item src="../assets/slide1.jpg" alt="Slideshow Foto">
      </v-carousel-item>
      <v-carousel-item src="../assets/slide2.jpg" alt="Slideshow Foto">
      </v-carousel-item>
      <v-carousel-item src="../assets/slide3.png" alt="Slideshow Foto">
      </v-carousel-item>
    </v-carousel>

    <!-- Text and Links -->
    <div class="my-6 mx-auto px-4" style="max-width: 1185px">
      <p>
        👋 <span class="font-weight-bold"> Herzlich Willkommen </span>
        auf der neuen CIS:web App - dein Zugang zur CISV Austria Datenbank!
      </p>
      <p>
        In den beiden unteren Karten findest du die Navigation zu den
        Stammdaten-Tabellen, sowie externe Links zu anderen CISV Portalen. Die
        Navigation erreichst du auch jederzeit über die linke Leiste, die man
        mit dem linken oberen Menü-Icon aus- und einfahren kann. Suchst du etwas
        bestimmtes? Dann probiere doch die globale Suche in der oberen Leiste.
        Wie auch das Klicken auf eine Tabellenzeile, führt sie dich beim
        Auswählen direkt zu dem Datenbankeintrag. Beachte jedoch, dass in dieser
        Version nur das Lesen und nicht das Bearbeiten oder Hinzufügen von
        Einträgen möglich ist. An einer erweiterten Version wird momentan noch
        tüchtig gearbeitet. 💻🔨
      </p>
      <p></p>
      <p>
        Sollte bei der App etwas nicht funktionieren oder falls du uns Feedback
        geben möchtest, dann
        <v-btn
          text
          color="accent"
          href="mailto:contact-project+cisv-vienna-cis20-cis-web-24402405-issue-@incoming.gitlab.com?subject=[BUG-REPORT]%20PROBLEM-BESCHREIBUNG"
        >
          <v-icon left>mdi-email-send</v-icon>
          schreib uns
        </v-btn>
        und wir kümmern uns darum so schnell wie möglich. 😉
      </p>
      <v-row justify="space-between" class="mt-2">
        <v-col>
          <internal-links-view />
        </v-col>
        <v-col>
          <external-links-view />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ExternalLinksView from "../components/ExternalLinks.vue";
import InternalLinksView from "../components/InternalLinks.vue";
export default {
  components: { ExternalLinksView, InternalLinksView },
  name: "Home",
};
</script>
