import Vue from "vue";
import "vuetify/dist/vuetify.min.css";

import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
