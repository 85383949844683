<template>
  <v-container fluid class="d-flex flex-column align-center pa-0">
    <!-- CISV Logo -->
    <v-img
      src="../assets/austria_combined_col.png"
      min-height="42"
      height="20vh"
      max-height="128"
      max-width="80vw"
      contain
      class="mt-6 mx-4"
      alt="CISV Logo"
    >
    </v-img>

    <!-- Image Slider -->
    <v-carousel
      cycle
      show-arrows-on-hover
      hide-delimiters
      height="clamp(128px, 20vh, 400px)"
      class="mt-4"
    >
      <v-carousel-item src="../assets/slide1.jpg" alt="Slideshow Foto">
      </v-carousel-item>
      <v-carousel-item src="../assets/slide2.jpg" alt="Slideshow Foto">
      </v-carousel-item>
      <v-carousel-item src="../assets/slide3.png" alt="Slideshow Foto">
      </v-carousel-item>
    </v-carousel>

    <!-- Login Button -->
    <v-btn @click="$emit('logIn')" x-large color="accent" class="mt-8">
      <v-icon left large>mdi-account</v-icon>
      <span class="ml-2" style="font-size: 1.25rem">Anmelden</span>
    </v-btn>

    <!-- Text -->
    <div class="my-8 px-4" style="max-width: 768px">
      <p>
        👋 <span class="font-weight-bold"> Herzlich Willkommen </span> auf der
        neuen CIS:web App - dein Zugang zur CISV Austria Datenbank!
      </p>
      <p>
        Melde dich mit deiner
        <span class="font-weight-bold">@cisv.at</span>-Adresse an, um Zugriff
        zur App zu erhalten, oder nutze die unteren externen Links um zu anderen
        CISV Portalen zu gelangen.
      </p>
    </div>

    <!-- External Links -->
    <external-links-view
      class="mx-4 mb-4"
      style="width: min(100%, 90vw); max-width: 512px"
    />
  </v-container>
</template>

<script>
import ExternalLinksView from "../components/ExternalLinks.vue";
export default {
  components: { ExternalLinksView },
  name: "LoggedOutView",
};
</script>
